import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import Seo from "../components/global/seo";
import CategoryMenu from "../components/blog/category-menu";
import convertId from "../utilities/convert-id";
import Content from "../components/utilities/content";
import CmsImage from "../components/utilities/cms-image";
import IpTracker from "../components/utilities/ip-tracker";
import FooterCTA from "../components/sections/footer-cta";
import { Helmet } from "react-helmet";

const Blog = ({
  data: {
    allWpPost: posts,
    allWpCategory: categories,
    wpPage: {
      title,
      page,
      sections: { sections },
    },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
}) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: `page-${convertId(title)}`,
        }}
      ></Helmet>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        themeSettings={themeSettings}
      />

      {sections &&
        sections.map((section) => (
          <section
            id={convertId(section.name)}
            className="page-title section-title-with-background-image"
          >
            <CmsImage className="section-img" image={section.image} />
            <div className="container">
              <div className="sz wrapper">
                <Content className="content-title-bg-image">
                  {section.content}
                </Content>
              </div>
            </div>
          </section>
        ))}

      <CategoryMenu page={page} categories={categories.edges} />
      <BlogElem>
        <Posts
          posts={posts}
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      </BlogElem>
      <IpTracker />
      <FooterCTA hideFooterCta={page.hideFooterCta} />
    </>
  );
};

export const pageQuery = graphql`
  query Blog($id: String!, $perPage: Int!, $skipItems: Int!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      slug
      id
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        hideFooterCta
      }
      sections {
        fieldGroupName
        sections {
          ... on WpPage_Sections_Sections_SectionTitleWithBackgroundImage {
            fieldGroupName
            name
            content
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    allWpCategory(sort: { order: ASC, fields: categorySettings___order }) {
      edges {
        node {
          id
          uri
          slug
          name
          parentId
        }
      }
    }

    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
    ) {
      edges {
        node {
          id
          title
          uri
          excerpt
          date(fromNow: true, formatString: "MMM DD YYYY")
          categories {
            nodes {
              id
              uri
              slug
              name
            }
          }
          tags {
            nodes {
              id
              uri
              slug
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          astroturfTv {
            fieldGroupName
            astroturfTvEmbed
          }
        }
      }
    }
  }
`;

export default Blog;
